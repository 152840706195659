import React, { useState } from 'react'
import BlankLayout from '../../component/layout/BlankLayout'
import IconMail from '../../component/icon/IconMail'
import IconLockDots from '../../component/icon/IconLockDots'
import { Link, useNavigate } from 'react-router-dom'
import api from '../../api/api'
import axios from 'axios'

type Props = {}

const Login = (props: Props) => {
    const navigate = useNavigate()
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const submitForm = async () => {
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/login`, {
            email: email,
            password: password,
            role:"coach"
        }).then( res => {
            // console.log("res", res)
            localStorage.setItem("user", JSON.stringify(res.data.user))
            localStorage.setItem("token", res.data.token)
            localStorage.setItem("role", res.data.user.school_id ? "school" : "academy")
            localStorage.setItem("firstName", res.data.user.first_name)
            navigate("/")
        }).catch( err => {
            console.log("err", err)
            alert("Invalid credentials")
        })
    }

    return (
        <BlankLayout>
        <div>
            <div className="absolute inset-0">
                <img src="/assets/images/auth/bg-gradient.png" alt="image" className="h-full w-full object-cover" />
            </div>

            <div className="relative flex min-h-screen items-center justify-center bg-cover bg-center bg-no-repeat px-6 py-10 dark:bg-[#060818] sm:px-16">
                <div className="relative w-full max-w-[870px] rounded-md bg-[linear-gradient(45deg,#fff9f9_0%,rgba(255,255,255,0)_25%,rgba(255,255,255,0)_75%,_#fff9f9_100%)] p-2 dark:bg-[linear-gradient(52.22deg,#0E1726_0%,rgba(14,23,38,0)_18.66%,rgba(14,23,38,0)_51.04%,rgba(14,23,38,0)_80.07%,#0E1726_100%)]">
                    <div className="relative flex flex-col justify-center rounded-md bg-white/60 backdrop-blur-lg dark:bg-black/50 px-6 lg:min-h-[758px] py-20">
                        <div className="mx-auto w-full max-w-[440px]">
                            <div className="mb-10">
                                <img className="w-20" src="/assets/images/koku.png" alt="logo"/>
                                <h1 className="text-3xl font-extrabold uppercase !leading-snug text-primary md:text-4xl">Sign in</h1>
                                <p className="text-base font-bold leading-normal text-white-dark">Enter your email and password to login</p>
                            </div>
                            <div className="space-y-5 dark:text-white">
                                <div>
                                    <label htmlFor="Email">Email</label>
                                    <div className="relative text-white-dark">
                                        <input value={email} onChange={(e)=>{setEmail(e.target.value)}} type="email" placeholder="Enter Email" className="form-input ps-10 placeholder:text-white-dark" />
                                        <span className="absolute start-4 top-1/2 -translate-y-1/2">
                                            <IconMail fill={true} />
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="Password">Password</label>
                                    <div className="relative text-white-dark">
                                        <input value={password} onChange={(e)=>{setPassword(e.target.value)}} type="password" placeholder="Enter Password" className="form-input ps-10 placeholder:text-white-dark" />
                                        <span className="absolute start-4 top-1/2 -translate-y-1/2">
                                            <IconLockDots fill={true} />
                                        </span>
                                    </div>
                                </div>
                                <button type="button" onClick={(e)=>(submitForm())} className="btn btn-primary rounded-3xl !mt-6 w-full border-0 uppercase shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)]">
                                    Sign in
                                </button>
                                <div className="text-center dark:text-white">
                                    Don't have an account ?&nbsp;
                                    <Link to="/register" className="uppercase text-primary underline transition hover:text-black dark:hover:text-white">
                                        REGISTER
                                    </Link>
                                </div>
                                <div className="text-center dark:text-white">
                                    Forgot Password ?&nbsp;
                                    <Link to="/reset-password" className="uppercase text-primary underline transition hover:text-black dark:hover:text-white">
                                        RESET
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </BlankLayout>
    )
}

export default Login