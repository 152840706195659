import React, { useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import IconMail from '../../component/icon/IconMail'
import IconLockDots from '../../component/icon/IconLockDots'
import IconUser from '../../component/icon/IconUser'
import axios from 'axios'
import BlankLayout from '../../component/layout/BlankLayout'
import { toast } from 'react-toastify'

type Props = {}

const RegisterNew = (props: Props) => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const email = searchParams.get("email")
    const [emailInput, setEmailInput] = useState(email || "")
    const [username, setUsername] = useState("")
    const [ic, setIc] = useState("")
    const [password, setPassword] = useState("")
    const [loading, setLoading] = useState(false)

    const submitForm = async () => {
        setLoading(true)
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/register`, {
            "email": email,
            "password": password,
            "confirm_password": password,
            "ic_number": ic,
            "name": username,
            "role": "coach"
        }, { headers: {'Content-Type': 'application/json', "Accept":"application/json"}, withCredentials: true }).then( res => {
            setLoading(false)
            console.log("res", res)
            if (res.status == 200) {
                // login user
                localStorage.setItem("user", JSON.stringify(res.data.data))
                localStorage.setItem("token", res.data.token)
                navigate("/")
            } else {
                toast.error("Error creating account")
            }
        }).catch( err => {
            setLoading(false)
            console.log("err register", err.response)
            toast.error(err.response ? err.response.data.error : "Error creating account")
        })
    }




    return (
        <BlankLayout>
            <div className="absolute inset-0">
                <img src="/assets/images/auth/bg-gradient.png" alt="image" className="h-full w-full object-cover" />
            </div>

            <div className="relative flex min-h-screen items-center justify-center bg-cover bg-center bg-no-repeat px-6 py-10 dark:bg-[#060818] sm:px-16">
                <div className="relative w-full max-w-[870px] rounded-md bg-[linear-gradient(45deg,#fff9f9_0%,rgba(255,255,255,0)_25%,rgba(255,255,255,0)_75%,_#fff9f9_100%)] p-2 dark:bg-[linear-gradient(52.22deg,#0E1726_0%,rgba(14,23,38,0)_18.66%,rgba(14,23,38,0)_51.04%,rgba(14,23,38,0)_80.07%,#0E1726_100%)]">
                    <div className="relative flex flex-col justify-center rounded-md bg-white/60 backdrop-blur-lg dark:bg-black/50 px-6 lg:min-h-[758px] py-20">
                        <div className="mx-auto w-full max-w-[440px]">
                            <div className="mb-10">
                                <img className="w-20" src="/assets/images/koku.png" alt="logo"/>
                                <h1 className="text-3xl font-extrabold uppercase !leading-snug text-primary md:text-4xl">Register New Account</h1>
                                <p className="text-base font-bold leading-normal text-white-dark">Enter your email and password to register</p>
                            </div>
                            <form className="space-y-5 dark:text-white">
                                <div>
                                    <label>Name</label>
                                    <div className="relative text-white-dark">
                                        <input type="text" placeholder="Enter Name" value={username} onChange={(e)=>setUsername(e.target.value)} className="form-input ps-10 placeholder:text-white-dark" />
                                        <span className="absolute start-4 top-1/2 -translate-y-1/2">
                                            <IconUser fill={true} />
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <label>IC</label>
                                    <div className="relative text-white-dark">
                                        <input type="text" placeholder="Enter IC" value={ic} onChange={(e)=>setIc(e.target.value)} className="form-input ps-10 placeholder:text-white-dark" />
                                        <span className="absolute start-4 top-1/2 -translate-y-1/2">
                                            <IconUser fill={true} />
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <label>Email</label>
                                    <div className="relative text-white-dark">
                                        <input type="email" disabled placeholder="Enter Email" value={emailInput} onChange={(e)=>setEmailInput(e.target.value)} className="form-input ps-10 placeholder:text-white-dark" />
                                        <span className="absolute start-4 top-1/2 -translate-y-1/2">
                                            <IconMail fill={true} />
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <label>Password</label>
                                    <div className="relative text-white-dark">
                                        <input type="password" placeholder="Enter Password" value={password} onChange={(e)=>setPassword(e.target.value)} className="form-input ps-10 placeholder:text-white-dark" />
                                        <span className="absolute start-4 top-1/2 -translate-y-1/2">
                                            <IconLockDots fill={true} />
                                        </span>
                                    </div>
                                </div>
                                <button type="button" className="btn btn-primary rounded-3xl !mt-6 w-full border-0 uppercase shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)]" onClick={()=>submitForm()}>
                                    {loading ? (<span className="animate-spin border-2 border-white dark:border-white !border-l-transparent rounded-full w-5 h-5 inline-flex"></span>) : "REGISTER"}
                                </button>
                            </form>
                            <div className="relative my-7 text-center md:mb-9">
                                <span className="absolute inset-x-0 top-1/2 h-px w-full -translate-y-1/2 bg-white-light dark:bg-white-dark"></span>
                                <span className="relative bg-white px-2 font-bold uppercase text-white-dark dark:bg-dark dark:text-white-light">or</span>
                            </div>
                            <div className="text-center dark:text-white">
                                Already have an account ?&nbsp;
                                <Link to="/login" className="uppercase text-primary underline transition hover:text-black dark:hover:text-white">
                                    SIGN IN
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BlankLayout>
    )
}

export default RegisterNew